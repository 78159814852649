import { User } from 'oidc-client-ts'

/**
 * All events that can occur in the app
 */
export enum AppEvent {
  UserLogin = 'user_login',
  FirstTimePromptSelected = 'first_time_prompt_selected',
  SpeciesSelected = 'species_selected',
  SpeciesSexSelected = 'species_sex_selected',
  StateSelected = 'state_selected',
  HuntTypeSelected = 'hunt_type_selected',
  TakeMethodSelected = 'take_method_selected',
  FutureHuntDatesSelected = 'future_hunt_dates_selected',
  OddsSearchExecuted = 'odds_search_executed',
  SortTypeSelected = 'sort_type_selected',
  OddsListSorted = 'odds_list_sorted',
  MainViewUpdated = 'main_view_updated',
  OddsCardSelected = 'odds_card_selected',
  MyProfileViewed = 'my_profile_viewed',
  MyPointsViewed = 'my_points_viewed',
  MyPointsStateAdded = 'my_points_state_added',
  MyPointsNewCardAdded = 'my_points_new_card_added',
  MyPointsCardEdited = 'my_points_card_edited',
  MyPointsCardDeleted = 'my_points_card_deleted',
  MyPointsStateRemoved = 'my_points_state_removed',
  BackToDrawOddsSelected = 'back_to_draw_odds_selected',
  ProfileUpdateInitiated = 'profile_update_initiated',
  ProfileUpdateDiscarded = 'profile_update_discarded',
  ProfileUpdateSaved = 'profile_update_saved',
  WebMapOpened = 'web_map_opened',
  AppForegrounded = 'app_foregrounded',
  BasemapMenuOpened = 'basemap_menu_opened',
  Enabled3d = '3d_enabled',
  Disabled3d = '3d_disabled',
  ResearchToolsOpened = 'research_tools_opened',
  GuidedTourInitiate = 'guided_tour_initiate',
  ShareLinkRequested = 'share_link_requested',
  UnitFilterApplied = 'unit_filter_applied',
}

/**
 * Where an event occured. Should be lowercase with the exception
 * of the first_time_prompt event origins. Should match the UI as
 * a rule of thumb
 */
export type Origin =
  | 'Sstart Searching'
  | 'Skip Guide'
  | 'Take Guided Tour'
  | 'filter card'
  | 'settings menu'
  | 'profile nav menu'
  | 'profile my points card'
  | 'personal information'
  | 'residency'
  | 'settings menu'
  | 'keyboard mouse tilt'
  | 'basemap menu'
  | 'start searching now'
  | 'take the guided tour'
  | 'hunt research tools'
  | 'first time prompt'
  | 'results page'

export type HuntType =
  | 'Any Hunt'
  | 'Youth Only'
  | 'Veteran Military'
  | 'Rut Season'

export type UserLoginEvent = { user: User }
export type FirstTimePromptSelectedEvent = { origin: Origin }
export type SpeciesSelectedEvent = { species: string }
export type SpeciesSexSelectedEvent = { species_sex: string }
export type StateSelectedEvent = { state: string }
export type HuntTypeSelectedEvent = { hunt_type: HuntType }
export type TakeMethodSelectedEvent = { take_method: string }
export type FutureHuntDatesSelectedEvent = {
  flexible_dates: boolean
  start_date: string
  end_date: string
}
export type OddsSearchExecutedEvent = {
  origin: Origin
  species: string
  species_sex: string
  state: string
  hunt_type: HuntType
  take_method: string
  flexible_dates: boolean
  start_date: string
  end_date: string
}
export type SortTypeSelectedEvent = {
  sort_type:
    | 'Public Sq Miles'
    | 'Draw Odds'
    | 'Harvest Rate'
    | 'Hunting Pressure'
    | 'Unit Name'
}
export type OddsListSortedEvent = { sort_type: 'ascending' | 'descending' }
export type MainViewUpdatedEvent = { view_type: string }
export type OddsCardSelectedEvent = {
  species: string
  species_detail: string
  state: string
  hunt_type: HuntType
  take_method: string
  flexible_dates: boolean
  start_date: string
  end_date: string
  unit: string
  draw_odds: number
  points: number
  tags_given: number
  harvest_rate: number
  total_land: number
  public_land: number
  hunter_per_sq_km: number
}
export type MyProfileViewedEvent = { origin: Origin }
export type MyPointsViewedEvent = { origin: Origin }
export type MyPointsStateAddedEvent = { state: string }
export type MyPointsNewCardAddedEvent = {
  state: string
  species: string
  points_type: string
}
export type MyPointsCardEditedEvent = {
  state: string
  species: string
  points_type: string
  edit_type: string
  total_card_points: number
}
export type MyPointsCardDeletedEvent = {
  state: string
  species: string
  points_type: string
  total_card_points: number
}
export type MyPointsStateRemovedEvent = { state: string }
export type BackToDrawOddsSelectedEvent = undefined
export type ProfileUpdateInitiatedEvent = { origin: Origin }
export type ProfileUpdateDiscardedEvent = { origin: Origin }
export type ProfileUpdateSavedEvent = {
  origin: Origin
  state_removed?: string
  state_added?: string
  states_applied?: string
}
export type WebMapOpenedEvent = undefined
export type AppForegroundedEvent = { is_authenticated: boolean }
// These should be checked
export type BasemapMenuOpenedEvent = {
  map_viewer_dimension: '3d' | '2d'
  active_basemap: 'hybrid'
}
export type Enabled3dEvent = {
  active_basemap: 'hybrid'
  origin: Origin
}
export type Disabled3dEvent = {
  active_basemap: 'hybrid'
  origin: Origin
}
export type ResearchToolsOpenedEvent = { origin: Origin }
export type GuidedTourInitiateEvent = { origin: Origin }
export type ShareLinkRequestedEvent = { origin: Origin }
export type UnitFilterAppliedEvent = {
  species: string
  state: string
  gmu_name: string
}

export type EventMap = {
  [AppEvent.UserLogin]: UserLoginEvent
  [AppEvent.FirstTimePromptSelected]: FirstTimePromptSelectedEvent
  [AppEvent.SpeciesSelected]: SpeciesSelectedEvent
  [AppEvent.SpeciesSexSelected]: SpeciesSexSelectedEvent
  [AppEvent.StateSelected]: StateSelectedEvent
  [AppEvent.HuntTypeSelected]: HuntTypeSelectedEvent
  [AppEvent.TakeMethodSelected]: TakeMethodSelectedEvent
  [AppEvent.FutureHuntDatesSelected]: FutureHuntDatesSelectedEvent
  [AppEvent.OddsSearchExecuted]: OddsSearchExecutedEvent
  [AppEvent.SortTypeSelected]: SortTypeSelectedEvent
  [AppEvent.OddsListSorted]: OddsListSortedEvent
  [AppEvent.MainViewUpdated]: MainViewUpdatedEvent
  [AppEvent.OddsCardSelected]: OddsCardSelectedEvent
  [AppEvent.MyProfileViewed]: MyProfileViewedEvent
  [AppEvent.MyPointsViewed]: MyPointsViewedEvent
  [AppEvent.MyPointsStateAdded]: MyPointsStateAddedEvent
  [AppEvent.MyPointsNewCardAdded]: MyPointsNewCardAddedEvent
  [AppEvent.MyPointsCardEdited]: MyPointsCardEditedEvent
  [AppEvent.MyPointsCardDeleted]: MyPointsCardDeletedEvent
  [AppEvent.MyPointsStateRemoved]: MyPointsStateRemovedEvent
  [AppEvent.BackToDrawOddsSelected]: BackToDrawOddsSelectedEvent
  [AppEvent.ProfileUpdateInitiated]: ProfileUpdateInitiatedEvent
  [AppEvent.ProfileUpdateDiscarded]: ProfileUpdateDiscardedEvent
  [AppEvent.ProfileUpdateSaved]: ProfileUpdateSavedEvent
  [AppEvent.WebMapOpened]: WebMapOpenedEvent
  [AppEvent.AppForegrounded]: AppForegroundedEvent
  [AppEvent.BasemapMenuOpened]: BasemapMenuOpenedEvent
  [AppEvent.Enabled3d]: Enabled3dEvent
  [AppEvent.Disabled3d]: Disabled3dEvent
  [AppEvent.ResearchToolsOpened]: ResearchToolsOpenedEvent
  [AppEvent.GuidedTourInitiate]: GuidedTourInitiateEvent
  [AppEvent.ShareLinkRequested]: ShareLinkRequestedEvent
  [AppEvent.UnitFilterApplied]: UnitFilterAppliedEvent
}
