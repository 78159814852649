import gql from 'graphql-tag'

export const QUERY_APP_START = gql`
  query hrtAppInitQuery {
    huntPlannerUserResidency {
      abbreviation
      id
      name
    }
    huntPlannerUserPoints {
      points
      type
      species {
        id
        name
      }
      state {
        abbreviation
        id
        name
      }
    }
    huntPlannerSpecies {
      id
      name
    }
    huntPlannerStates {
      name
      id
      abbreviation
    }
    huntPlannerWeapons {
      id
      name
    }
  }
`
