import gql from 'graphql-tag'

export const MUTATE_POINTS = gql`
  mutation hrtPointsMutation($input: UpdatedHuntPlannerUserPoints!) {
    updateHuntPlannerUserPoints(input: $input) {
      points
      type
      species {
        id
        name
      }
      state {
        abbreviation
        id
        name
      }
    }
  }
`
